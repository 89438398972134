import { createRouter, createWebHashHistory } from "vue-router";
const routes = [
  {
    path: '/',
    redirect: '/home2'
  },
  {
    path: '/home',
    component: () => import('../views/index/IndexPage.vue')
  },
  {
    path: '/home2',
    component: () => import('../views/index/IndexPage2.vue')
  }
];
const router = createRouter({
  history: createWebHashHistory(),
  routes
});
export default router;
