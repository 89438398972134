<template>
  <div id="app">
    <router-view />
    <div
        style="z-index:10000;position:fixed;text-align:center;bottom:0;margin:0 auto;width:100%;color: #5c6b77"
    >
      <a
          target="_blank"
          style="color: #5c6b77"
          href="https://beian.miit.gov.cn/"
      >备案号:鲁ICP备2022030405号-1</a>
      &nbsp; @2023-2024
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>
